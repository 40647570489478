<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12">
      <div class="card-header border-0 justify-content-between">
        <div class="card-title">{{ $route.name }} List</div>
        <div class="flex flex-row mb-2 float-right">
          <el-button v-if="currentRole.can_create" type="primary" @click="doShowModalBroadcast" size="small"><font-awesome-icon icon="plus"/> Create New Broadcast</el-button>
        </div>
      </div>
      <div class="card-body">
        <div class="flex">
          <div class="w-1/2">
            <el-form ref="form" label-width="120px">
              <el-form-item label="Filter Type">
                <el-select size="small" v-model="filter.search_by" class="mr-2 w-full">
                  <el-option v-for="(item, index) in search_options" :key="index" :value="item.value" :label="item.text"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Search Keyword" v-if="filter.search_by != 'recipient_type'">
                <el-input clearable size="small" type="text" v-model="filter.search_keyword" placeholder="Input search keyword" class="mr-2"/>
              </el-form-item>
              <el-form-item label="Search Keyword" v-else>
                <el-select
                  class="w-full" size="small"
                  v-model="filter.search_keyword"
                  placeholder="Select recipient segmentation type">
                  <el-option value="" label="All">All</el-option>
                  <el-option v-for="(item, index) in segmentation_options" :value="item.value" :key="index" :label="item.label"/>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="w-1/2">
            <el-form ref="form" label-width="120px">
              <el-form-item label="Broadcast Date">
                <el-date-picker
                  v-model="filter.date_filter"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  size="small"
                  class="w-full"
                  range-separator="To"
                  start-placeholder="Start date"
                  cleareable="false"
                  @clear="searchHandler"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
      </div>
      <div class="table-responsive" v-loading="is_loading">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="10%">Target </th>
              <th width="20%">Title</th>
              <th>Message</th>
              <th width="15%">Recipient Segment</th>
              <!-- <th width="10%">Estimated Recipient</th> -->
              <th width="20%">Broadcast Type</th>
              <th width="20%">Broadcast Date</th>
              <th width="20%">Action</th>
              <!-- <th>#</th> -->
            </tr>
          </thead>
          <tbody :loading="is_loading">
            <tr v-for="(item, i) in list" :key="i">
              <td>{{ item.target === 'device' ? 'Device' : item.target === 'topic' ? 'Topic' : item.target }}</td>
              <td>
                <el-popover v-if="item.message && item.message.length > 25"
                  placement="right"
                  width="300"
                  trigger="hover">
                  <p style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">
                    {{ item.title }}
                  </p>
                  <span slot="reference">{{ truncate(item.title, { length: 20 }) }}</span>
                </el-popover>
                <span v-else>
                  {{ item.title }}
                </span>
              </td>
              <td>
                <el-popover v-if="item.message"
                  placement="right"
                  width="300"
                  trigger="hover">
                  <p style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">
                    {{ item.message }}
                  </p>
                  <span slot="reference">{{ truncate(item.message, { length: 30 }) }}</span>
                </el-popover>
              </td>
              <td>
                {{ item.recipient_type_str }}
                <el-popover v-if="item.segmentation_info"
                  placement="right"
                  width="300"
                  trigger="hover">
                  <p style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">{{ item.segmentation_info }}</p>
                  <!-- <span slot="reference"><font-awesome-icon icon="info-circle"/></span> -->
                </el-popover>
              </td>
              <!-- <td>{{ item.total_recipient }}</td> -->
              <td>{{ item.is_scheduled ? 'Schedule' : 'Immediately' }}</td>
              <td>{{ item.broadcast_date_str }}</td>
              <td>
                <span v-if="item.is_scheduled">
                  <el-button
                    :disabled="item.schedule_executed || item.notif_status === 'cancelled'"
                    @click="cancelSchedule(item)"
                    title="Cancel Schedule"
                    size="mini"
                    type="danger"
                    round
                  >
                    {{ item.notif_status === 'cancelled' ? 'Cancelled' : (item.schedule_executed ? 'Compeleted' : 'Cancel') }}
                  </el-button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
      </div>
      <pagination
        :page="filter.page"
        :per_page="filter.per_page"
        :list="list"
        :total_rows="total_rows"
        @pageChange="pageChangeHandler"/>
    </div>
    <create-or-update-broadcast @hide="showModalBroadcast = false"
      :show="showModalBroadcast"/>
  </div>
</template>
<script>
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import {
  GET_BROADCASTHISTORY, CREATE_BROADCAST, CANCEL_SCHEDULED_BROADCAST
} from '@/store/modules/broadcast';
import {
  GET_COMPANIES,
} from '@/store/modules/companies';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
import {
  truncate, clone, isEmpty, uniqBy, isEqual, cloneDeep,
} from 'lodash';
import { GET_MERCHANTS } from '@/store/modules/merchants';
import { GET_VOUCHERS } from '@/store/modules/vouchers';
import url from 'url';
import PaginationVue from '../components/Pagination.vue';
import CreateOrUpdate from './broadcast/CreateOrUpdate.vue';

const deepLinkBaseURL = 'app://app.fortuna.venteny';

export default {
  components: { Pagination: PaginationVue, CreateOrUpdateBroadcast: CreateOrUpdate },
  name: 'Broadcast',
  metaInfo: {
    title: 'Broadcast',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      deepLinkBaseURL,
      truncate,
      emptyStateImage,
      list: [],
      total_rows: 0,
      is_loading: true,
      targetLoading: false,
      page: 1,
      per_page: 20,
      showModalBroadcast: false,
      segmentation_options: [
        {
          value: 'all',
          label: 'All User',
        },
        {
          value: 'private',
          label: 'Private User',
        },
        {
          value: 'public',
          label: 'Public User',
        },
        {
          value: 'segment_yesterday_register',
          label: 'Registered Yesterday',
        },
        {
          value: 'segment_yesterday_transaction',
          label: 'Do Transaction Yesterday',
        },
        {
          value: 'segment_today_register',
          label: 'Registered Today',
        },
        {
          value: 'segment_today_transaction',
          label: 'Do Transaction Today',
        },
        {
          value: 'segment_month_register',
          label: 'Registered This Month',
        },
        {
          value: 'segment_month_transaction',
          label: 'Do Transaction This Month',
        },
        {
          value: 'segment_lastmonth_register',
          label: 'Registered Last Month',
        },
        {
          value: 'segment_lastmonth_transaction',
          label: 'Do Transaction Last Month',
        },
        {
          value: 'segment_custom_register',
          label: 'Custom Register Date',
        },
        {
          value: 'segment_custom_transaction',
          label: 'Custom Transaction Date',
        },
        {
          value: 'csv',
          label: 'CSV',
        },
      ],
      destination_options: [
        {
          value: '',
          label: 'Home',
        },
        {
          value: 'custom',
          label: 'Custom',
        },
        {
          value: '/merchant',
          label: 'Merchant Voucher List',
        },
        {
          value: '/voucher',
          label: 'Voucher',
        },
        {
          value: '/history',
          label: 'Transaction History',
        },
      ],
      filter: {
        page: 1,
        per_page: 20,
        search_by: 'title',
        search_keyword: '',
        date_filter: [],
      },
      search_options: [
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'Message',
          value: 'message',
        },
        {
          text: 'Recipient Type',
          value: 'recipient_type',
        },
      ],
      schedule: {
        date: '',
        time: '',
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'),
        shortcuts: [
          {
            text: 'Today',
            onClick(picker) {
              picker.$emit('pick', new Date());
            },
          },
          {
            text: 'Tommorow',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', date);
            },
          },
          {
            text: 'Next Week',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            },
          },
          {
            text: 'Next Month',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit('pick', date);
            },
          },
        ],
      },
      form: {
        title: '',
        message: '',
        recipient_type: '',
        type: 'text',
        start_date: '',
        end_date: '',
        file: null,
        recipients: '',
        page_destination: '',
        target: '',
        company_id: [],
        is_scheduled: false,
      },
      loading: {
        broadcast: false,
        company: false,
      },
      target_list: [],
      timeoutTargetFilter: null,
      timeoutSearchCompany: null,
      filterTargetKeyword: '',
      tempTarget: {},
      companies: {
        rows: [],
        count: 0,
      },
    };
  },
  validations() {
    return {
      form: {
        title: { required },
        message: { required },
        recipient_type: { required },
        start_date: { required: requiredIf(this.isCustomSegment) },
        end_date: { required: requiredIf(this.isCustomSegment) },
        recipients: { required: requiredIf(this.recipient_type === 'csv') },
      },
      schedule: {
        date: { required: requiredIf(this.form.is_scheduled) },
        time: { required: requiredIf(this.form.is_scheduled) },
      },
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.filter.page - 1) * this.filter.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.filter.per_page) {
          return this.total_rows;
        }
        return this.filter.page * this.filter.per_page;
      }
      return 0;
    },
    currentRole() {
      return this.$store.getters.current_role;
    },
    isCustomSegment() {
      return this.form.recipient_type.indexOf('custom') !== -1;
    },
    timePickerOptions() {
      const now = () => moment();
      return {
        step: '00:30',
        start: moment(this.schedule.date).isSame(now(), 'day') ? now().add(30, 'minute').format(`HH:${now().format('mm') > 30 ? '00' : '30'}`) : '00:00',
        end: '24:00',
        format: 'HH:mm',
      };
    },
  },

  //del
  //   created() {
  //   const storedScheduleExecuted = localStorage.getItem('scheduleExecuted');
  //   if (storedScheduleExecuted !== null) {
  //     this.$set(item, 'schedule_executed', JSON.parse(storedScheduleExecuted));
  //   }
  // },
  async mounted() {
    /* eslint-disable radix */
    const loader = this.$loading.show();
    if (!isEmpty(this.$route.query)) {
      this.filter = cloneDeep(this.$route.query);
      this.filter.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      this.filter.per_page = this.$route.query.per_page ? parseInt(this.$route.query.per_page) : 1;
    }
    await this.getList();
    loader.hide();
  },

  methods: {
    updateURLQuery() {
      if (!isEqual(this.$route.query, this.filter)) {
        this.$router.replace({
          name: this.$route.name,
          query: this.filter,
        }).catch(() => {});
      }
    },
    searchHandler() {
      this.filter.page = 1;
      this.getList();
    },
    async getList() {
      this.is_loading = true;
      this.updateURLQuery();
      await this.$store.dispatch(GET_BROADCASTHISTORY, this.filter).catch(() => {});
      const { rows, count } = this.$store.getters.broadcast_history;
      this.total_rows = count || 0;
      this.list = rows;
      this.is_loading = false;
    },
    pageChangeHandler(page) {
      this.filter.page = page;
      this.updateURLQuery();
    },
    doShowModalBroadcast() {
      this.showModalBroadcast = true;
      this.v$.form.$touch();
    },
    async doBroadcast() {
      this.loading.broadcast = true;
      const obj = clone(this.form);
      delete obj.file;
      const deeplink_query = {};
      if (obj.page_destination === '/voucher') {
        deeplink_query.voucher_id = obj.target;
      } else if (obj.page_destination === '/merchant') {
        deeplink_query.merchant_id = obj.target;
      } else if (obj.page_destination === 'custom') {
        obj.page_destination = `/${obj.target}`;
      }
      obj.deeplink = `${deepLinkBaseURL}${url.format({ pathname: obj.page_destination, query: deeplink_query })}`;
      if (obj.is_scheduled) {
        obj.schedule_date = `${[this.schedule.date, this.schedule.time].join(' ')}:00`;
      }
      delete obj.page_destination;
      delete obj.target;

      await this.$store.dispatch(CREATE_BROADCAST, obj)
        .then(() => {
          this.$message({
            title: 'Success',
            type: 'success',
            message: 'Broadcast success',
          });
          this.closeModalBroadcast();
          this.getList();
        }).catch((err) => {
          popupErrorMessages(err?.response?.data);
        });
      this.loading.broadcast = false;
    },
    closeModalBroadcast() {
      this.showModalBroadcast = false;
      this.form = {
        title: '',
        message: '',
        recipient_type: '',
        type: 'text',
        start_date: '',
        end_date: '',
        recipients: '',
        page_destination: '',
        target: '',
      };
    },
    async handlerInputFile() {
      const str = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(this.form.file);
        reader.onload = () => resolve(reader.result.replace(/\\r\\n|\\r|\\n|\\n\\r/igm, ''));
        reader.onerror = (error) => reject(error);
      });
      this.form.recipients = `data:text/csv;base64,${Buffer.from(str).toString('base64')}`;
    },
    pageDestinationChangeHandler(value) {
      this.tempTarget = {};
      this.form.target = '';
      if (value === '/merchant') {
        this.getMerchantList();
      } else if (value === '/voucher') {
        this.getVoucherList();
      }
    },
    targetFilterHandler(value) {
      clearTimeout(this.timeoutTargetFilter);
      this.timeoutTargetFilter = setTimeout(() => {
        if (this.form.page_destination === '/merchant') this.getMerchantList(value);
        if (this.form.page_destination === '/voucher') this.getVoucherList(value);
      }, 500);
    },
    targetChangeHandler(value) {
      this.tempTarget = this.target_list.find((v) => v.value === value);
    },
    async getMerchantList(keyword) {
      this.targetLoading = true;
      await this.$store.dispatch(GET_MERCHANTS, {
        search_by: 'merchant_name',
        search_keyword: keyword,
        status_filter: 'active',
      }).catch(() => {});
      this.target_list = [{
        label: 'No Target',
        value: '',
      }];
      if (!isEmpty(this.tempTarget)) {
        this.target_list.push(this.tempTarget);
      }
      const { rows } = this.$store.getters.merchants;
      this.target_list.push(...rows.map((v) => ({ value: v.merchant_id, label: v.merchant_name })));
      this.target_list = uniqBy(this.target_list, 'value');
      this.targetLoading = false;
    },
    async getVoucherList(keyword = '') {
      this.targetLoading = true;
      await this.$store.dispatch(GET_VOUCHERS, {
        page: 1,
        per_page: 20,
        search_by: 'voucher_name',
        search_keyword: keyword,
        status_filter: 'active',
      }).catch(() => {});
      this.target_list = [{
        label: 'No Target',
        value: '',
      }];
      if (!isEmpty(this.tempTarget)) {
        this.target_list.push(this.tempTarget);
      }
      const { rows } = this.$store.getters.vouchers;
      this.target_list.push(...rows.map((v) => ({ value: v.voucher_id, label: v.voucher_name })));
      this.target_list = uniqBy(this.target_list, 'value');
      this.targetLoading = false;
    },
    async loadCompanies({ search_keyword = '' } = {}) {
      this.loading.company = true;
      await this.$store.dispatch(GET_COMPANIES, {
        search_keyword,
      });
      this.loading.company = false;
      const { rows } = this.$store.getters.companies;
      this.companies.rows = this.companies.rows.filter((v) => this.form.company_id.includes(v.company_id));
      this.companies.rows.push(...rows);
    },
    searchCompany(value) {
      clearTimeout(this.timeoutSearchCompany);
      this.timeoutSearchCompany = setTimeout(() => {
        this.loadCompanies({
          search_keyword: value,
        });
      }, 1000);
    },
    handlerChangeSegment(value) {
      if (value === 'private') {
        this.form.company_id = [];
        this.loadCompanies();
      }
    },
    handlerChangeBroadcastDate(value) {
      console.log(moment(value).format(), moment().format(), moment(value).isSameOrBefore(moment(), 'days'), moment(this.schedule.time, 'HH:mm').isBefore(moment(), 'hour'));
      if (moment(value).isSame(moment(), 'days')) {
        if (this.schedule.time && moment(this.schedule.time, 'HH:mm').isBefore(moment(), 'hour')) {
          this.schedule.time = '';
        }
      }
    },
    async cancelSchedule(item) {
      let cancelConfirmationMessage = 'Are you sure you want to cancel this broadcast?';
      try {
        await this.$confirm(cancelConfirmationMessage, 'Confirmation', {
          type: 'warning',
          confirmButtonText: 'Yes',
        });

        const response = await this.$store.dispatch(CANCEL_SCHEDULED_BROADCAST, item._id);

        this.$set(item, 'schedule_executed', true);
        this.$set(item, 'notif_status', 'cancelled'); 

        this.updateIsCanceledState(item._id, true);

        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Broadcast canceled successfully',
        });

        console.log(`Broadcast with ID ${item._id} canceled successfully.`);
      } catch (error) {
        console.error('Error canceling broadcast:', error);
      }
    },
    updateIsCanceledState(broadcastId, isCanceled) {
      localStorage.setItem(`isCanceled_${broadcastId}`, JSON.stringify(isCanceled));
    },
  },
  /* eslint-disable object-shorthand */
  watch: {
    'schedule.date'() {
      if (moment(this.schedule.date).isSame(moment(), 'days')) {
        if (this.schedule.time && moment(this.schedule.time, 'HH:mm').isBefore(moment(), 'hour')) {
          this.schedule.time = '';
        }
      }
    },
  },
};
</script>
